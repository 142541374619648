.error {
    color:red !important;
    
}

.warning {
    color:blue !important;
    
}

.success {
    color:green !important;
    
}




.buy-tea-container{
    overflow-y:auto; 
    height:60%;
    margin: 0 auto;
    
    width: 80%;
        
  }