
canvas{
    position:absolute;
    background:white;
    z-index: -2;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    filter:blur(10px) contrast(30);
    
  
}


canvas.dark{
    position:absolute;
    background:black;
    z-index: -2;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    filter:blur(10px) contrast(30);
    

}