.App {
  text-align: center;
}

.SwiperDots{
  justify-content: center;
  align-items: center;
  text-align:center;
  

}
.swiper-dot-container{
  position: fixed;
  bottom: 4.25rem;
  right: 50%;
  text-align: center;
  font-size: 1rem;
  display: flex;
  gap: 5px;
  transform: translate(50%, 0);
  
}



