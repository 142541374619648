body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


a, a:visited, a:active{
  text-decoration:none;
  color: black;
  
}

.links.dark > li > a{
  color: black;
}

.btn-nice a{
  text-decoration:none;
  color:black;
 
 
}

.btn-nice a:hover{
  text-decoration:none;
  color:white;
 
 
}



.btn-nice{
  
  border:solid 2px #1f1f1f;
  background: transparent;
  color: #1f1f1f;
  padding:5px;
  margin: 2px;
}




.btn-nice:hover,
.btn-nice:active,
.btn-nice:hover *,
.btn-nice:active *{
  
  border:solid 2px #1f1f1f;
  background: #1f1f1f;
  color: #fff;

}





main {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  
  
  
}

.main-area {
 width:80%;

 overflow-y:hidden;
 margin: 75px auto;
 height:80vh;
 border: 2px solid black;
 background: white;

 
  
}





.sidebar-toggle {
  position: fixed;
  top: 1rem;
  right: 1rem;
  font-size: 2rem;
  background: transparent;
  border-color: transparent;
  color: var(--clr-primary-5);
  transition: var(--transition);
  cursor: pointer;
  z-index: 3;

 
}

.nav-arrow {
  font-size: 3.5rem;
  
  
}
.nav-arrow-left{
  position: fixed;
  left: 1rem;
  bottom:50%;
}
.nav-arrow-right{
  position: fixed;
  right: 1rem;
  bottom:50%;
  
}

.nav{
  
  background: red;
}

.main-area{
  
  
}



.sidebar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.5rem;
}
ul {
  list-style-type: none;
  

}
li{
  text-transform: uppercase;
  text-decoration:none;
}


.links {
  
  font-size: 1.25rem;
  margin: 20px 0 0 0;
  padding: 0;

}
  
.links > li > a{
  
    
  text-decoration: none; 
  
  
  

  

}
  
.links > li > a:visited {
  
    
    text-decoration: none; 
    
    
    
  
    
  
}



.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 99%;
  height: 25%;
  background: white;
  display: grid;
  grid-template-rows: auto 1fr auto;
  row-gap: 1rem;
  box-shadow: var(--clr-red-dark);
  transition: all 0.3s linear;
  transform: translate(0,-100%);
  border: 2px solid black;
  
  box-shadow: none;
  z-index: 2;
  
}
.show-sidebar {
  transform: translate(0);
  box-shadow: 1px 1px 5px grey;
}


.sidebar-icons{
  align-items: center;
  text-align: center;
  justify-content: center;
  margin: 20px auto;
  font-size: 2rem;
  display: flex;
  flex-direction: row;
  gap:10px;
 

}









.switch {
  position: absolute;
  display: inline-block;
  width: 70px;
  height: 34px;
  
  top: 1.05rem;
  right: 5rem;
  z-index: 3;
}


.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white; 
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {

  background-color:  #03080d; 
}

input:focus + .slider {
  box-shadow: 0 0 1px #ccc;
}

input:checked + .slider:before {
  -webkit-transform: translateX(35px);
  -ms-transform: translateX(35px);
  transform: translateX(35px);
}


.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
  background-color:  #03080d; 
}

input:checked + .slider.round:before {
  background-color: white;
}



.switch .labels {
  position: absolute;
  top: 10px;
  left: 0;
  width: 100%;
  height: 100%;
  font-size: 12px;
  font-family: sans-serif;
  transition: all 0.4s ease-in-out;
}


.switch .labels::after {
  content: attr(data-off);
  position: absolute;
  right: 5px;
  color: #4d4d4d;
  opacity: 1;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
  transition: all 0.4s ease-in-out;
}

.switch .labels::before {
  content: attr(data-on);
  position: absolute;
  left: 5px;
  color: #ffffff;
  opacity: 0;
  text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.4);
  transition: all 0.4s ease-in-out;
}

.switch input:checked~.labels::after {
  opacity: 0;
}

.switch input:checked~.labels::before {
  opacity: 1;
}


.portfolio-item-container{
  overflow-y:auto;
  margin: 0 auto;
 
  width: 80%;
  height: 90%;
  display: flex;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
  padding-top:20px;
}


*::-webkit-scrollbar-track
{
	border: 1px solid black;
	background-color: #F5F5F5;
}

*::-webkit-scrollbar
{
	width: 10px;
	background-color: #F5F5F5;
}

*::-webkit-scrollbar-thumb
{
	background-color: #000000;	
}


.portfolio-image{
  height:40%;
  width:40%;
}

.portfolio-item{
  height:55%;
  width:40%;
  margin: 10px auto;
  border: 2px solid black;
}


.footer-container{
    position: fixed;
    bottom: 6px;
    right: 50%;
    text-align: center;
    align-items: center;
    font-size: 0.7rem;
    display: flex;
    flex-direction: column;
  
    transform: translate(50%, 0);
    color:#818589;
    color: rgba(0, 0, 0, 1);

   
}

.footer-container > p{
  margin: -3px !important;
  padding:0 !important;

}

.footer-icon{
  margin: 3px !important;
  padding:0 !important;
  font-size: 1.1rem;
  display: flex;
  flex-direction: row;
  gap:5px;

}


@media screen and (min-width: 676px) {
  .sidebar {
    width: 300px;
    height: 100%;
    transform: translate(-100%);
    transition: all 0.3s linear;
  }
  .show-sidebar {
    transform: translate(0);
  }
  
  

  ul {
    list-style-type: none;
    padding: 0;
    margin: 20px 0 0 0;
  }
  
  
  .links {
    
    font-size: 1.25rem;
    text-transform: uppercase;
    text-decoration: none; 
    
    color: var(--clr-grey-3);
    transition: var(--transition);
    letter-spacing: var(--spacing);
  }
  .links > li > a{
  
    
    text-decoration: none; 
    
    
    
  
    
  
  }
  .links > li > a:visited {
  
    
    text-decoration: none; 
    
  
  }

 


  
}




@media screen and (max-width: 676px) {
 
  .switch {
    position: absolute;
    display: inline-block;
    width: 70px;
    height: 34px;
    top: 1.05rem;
    left: 1rem;
    
    
    
  }
  .portfolio-item {
    font-size: 0.75rem;
    height:45%;
    width:45%;
    margin: 5px auto;
    
  }

  .portfolio-item-container{
    gap: 5px;
    width:95%;
  }

  .portfolio-image{
    height:20%;
    width:60%;
  }

  .links{

  }

  .nav-arrow {
    font-size: 3.5rem;
   
    
  }
  .nav-arrow-left{
    position: fixed;
    left: -10px;
    bottom:50%;
 
  }
  .nav-arrow-right{
    position: fixed;
    right: -10px;
    bottom:50%;

    
  }



}
