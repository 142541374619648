main.dark {
    
    color: white;
    
}
.main-area.dark{
  border: 2px solid white;
  background: black;
}


body.dark{
    background: black;
    color: white;
}

.btn-nice.dark a{
    text-decoration:none;
    color:white;
   
   
  }
  
.btn-nice.dark a:hover{
    text-decoration:none;
    color:black;
   
   
}
  
  
  
.btn-nice.dark{
    
    border:solid 2px #fff;
    background: transparent;
    color: #fff;
    padding:5px;
    margin: 2px;
}
  
  
  
  
  .btn-nice.dark:hover,
  .btn-nice.dark:active,
  .btn-nice.dark:hover *,
  .btn-nice.dark:active *{
    
    border:solid 2px #fff;
    background: #fff;
    color: black;
  
  }


  .footer-container.dark{
    position: fixed;
    bottom: 6px;
    right: 50%;
    text-align: center;
    align-items: center;
    font-size: 0.75rem;
    display: flex;
    flex-direction: column;
  
    transform: translate(50%, 0);
    
    color: rgba(255, 255, 255, 1);

   
}

.dark > a{
  text-decoration:none;
  color: white;
  
}

a.dark{
  text-decoration:none;
  color: white;
} 

.links.dark > li > a{
  color: white;
}

.sidebar.dark {
  
  background: black !important;
 
  border: 2px solid white;
  
  box-shadow: none;
  
}

.show-sidebar.dark {
  
  
 
  border: 2px solid white;
  box-shadow: 1px 1px 5px grey;
  
  
}

.nav-arrow.dark{
  color: white;
}


.dark > *::-webkit-scrollbar-track
{
	border: 1px solid white;
	background-color: #0A0A0A;
}

.dark > *::-webkit-scrollbar
{
	width: 10px;
	background-color: #0A0A0A;
}

.dark > *::-webkit-scrollbar-thumb
{
	background-color: #FFFFFF;	
}

.dark > .portfolio-item-container > .portfolio-item
{
	border: 2px solid white;	
}


.dark > .buy-tea-container > .portfolio-item
{
	border: 2px solid white;	
}

.dark > .form-container > form > .form-input> .form-control{
  background: black;
  color: white;

}